import { DragEvent, ChangeEvent } from 'react';
import { FileFormatType } from '../../typedefs';
import { DropBoxProps } from './'
import { getFileExtension } from '../../utils/generalFunctions';

export const handleDrop = (
    e: DragEvent<HTMLElement>,
    filterFiles: FileFormatType[],
    callBack?: () => void,
    onFileReceive?: DropBoxProps['onFileReceive'],
    onWrongFileReceive?: (filename: string) => void // a callback which runs when file not with filterFiles
) => {
    e.preventDefault();
    e.stopPropagation();
    callBack && callBack();
    console.log("files ==>", filterFiles);
    const _files = e.dataTransfer.files
    const files: File[] = [];
    for (let x = 0; x < _files.length; x++) {
        const file = _files.item(x);
        if (file === null) continue;
        if (filterFiles.length > 0 && filterFiles.findIndex(({ extension }) => extension === getFileExtension(file.name)) === -1) {
            onWrongFileReceive && onWrongFileReceive(file.name);
            continue;
        }
        files.push(file);
    }
    console.log(files);
    files.length > 0 && onFileReceive && onFileReceive(files) // if file is not null and onFileReceive is not undefined then run this line
}

export const handleDragOver = (e: DragEvent<HTMLElement>, callBack?: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    callBack && callBack();
}
export const handleDragEnter = (e: DragEvent<HTMLElement>, callBack?: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
    callBack && callBack();
}
export const handleDragLeave = (e: DragEvent<HTMLElement>, callBack?: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    callBack && callBack();
}

export const browseFile = (
    e: ChangeEvent<HTMLInputElement>,
    filterFiles: FileFormatType[],
    onFileLoad?: DropBoxProps['onFileReceive'],
    onWrongFileReceive?: (filename: string) => void // a callback which runs when file not with filterFiles

) => {

    const _files = e.target.files;
    if (!_files) return;
    const files: File[] = [];
    for (let x = 0; x < _files.length; x++) {
        const file = _files.item(x);
        if (file === null) continue;
        if (filterFiles.length > 0 && filterFiles.findIndex(({ extension }) => extension === getFileExtension(file.name)) === -1) {
            onWrongFileReceive && onWrongFileReceive(file.name);
            continue;
        }
        files.push(file);
    }
    console.log(files);
    files.length > 0 && onFileLoad && onFileLoad(files) // if file is not null and onFileReceive is not undefined then run this line
}