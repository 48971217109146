import React, { FC, ReactNode } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Typography, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import { appTheme } from '../../globalStyle/appTheme';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            borderBottom: "1px solid grey",
            backgroundColor: appTheme.colors.default,
            boxShadow: '0 0 2px 0 grey',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '100%',
            flexShrink: 0,
        },
        accordion: {
            backgroundColor: appTheme.colors.default
        }
    }),
);

export interface Props {
    title: string,
    collapse: ReactNode,
}
const _Accordion: FC<Props> = ({ title, collapse }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Accordion className={classes.accordion} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {collapse}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default React.memo(_Accordion);
