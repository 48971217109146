import React, { FC, ReactNode } from 'react';
import { Stepper as MiStepper, Step, StepLabel, StepIconProps } from '@material-ui/core';
import { IcePop as IconType } from 'mdi-material-ui';
// import { FlipTwoTone, GetAppTwoTone, HorizontalSplitTwoTone, MoveToInboxOutlined } from '@material-ui/icons/';
import clsx from 'clsx';
import {useColorlibStepIconStyles, ColorlibConnector} from './style';

export interface StepperProps {
    iconsLables: {
        Icon: typeof IconType,
        label: ReactNode,
    }[]
}

function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {props.icon}
        </div>
    );
}


const Stepper: FC<StepperProps> = ({ iconsLables }) => {

    return (
        <div>
            <MiStepper alternativeLabel activeStep={5} connector={<ColorlibConnector />}>
                {iconsLables.map(({ Icon, label },idx) => (
                    <Step key={idx}>
                        <StepLabel icon={<Icon />} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </MiStepper>
        </div>
    )
}

// import * as s from './style';

// export interface StepProps {
//     Icon: typeof IconType,
//     lable: ReactNode,
// }
// const Step: FC<StepProps> = ({ Icon, lable }) => {
//     return (
//         <s.StepWrap>
//             <s.IconWrap><Icon /></s.IconWrap>
//             <div>{lable}</div>
//         </s.StepWrap>
//     )
// }


// export interface StepperProps {
//     iconsLables: StepProps[]
// }
// const Stepper: FC<StepperProps> = ({ iconsLables }) => {
//     return (
//         <s.StepperWrap>
//             {
//                 iconsLables.map(({ Icon, lable }, idx) => (
//                     <div>
//                         {/* {idx===0 || <span />} */}
//                         <Step key={idx} Icon={Icon} lable={lable} />
//                     </div>
//                 ))
//             }
//         </s.StepperWrap>
//     )
// }

export default Stepper
