import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import Row from './Row';
import { FileStateType } from '../../typedefs';

////////////////////////////////////////// Type defs  ///////////////////////////////////////////////////
export interface WrapperProp {

}

export interface FileViewTablerProps {
    filesDetailsArray: FileStateType[],
    onRowRemove?: (id: number) => void,
    defaultConvertFrmt?: string,
}

////////////////////////////////////// styled components ///////////////////////////////////////////////////
export const TableWrapper = styled.div`
    width : 100%;
`

/////////////////////////////////////////// Functional components ////////////////////////////////////////
const FileViewTable: FC<FileViewTablerProps> = ({ filesDetailsArray, onRowRemove, defaultConvertFrmt }) => {

    return (
        <TableWrapper>
            {
                filesDetailsArray.map((state, idx) => (
                    <Row key={idx} {...state} onRowRemove={onRowRemove} defaultConvertFrmt={defaultConvertFrmt} />
                ))
            }
        </TableWrapper>
    )
};

//// Exports
export default React.memo(FileViewTable);
export { Row, FileStateType }