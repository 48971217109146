import http from './http';
import {FileFormatType} from '../typedefs';

interface CommonType {
    onError: (msg: string) => void,
    beforeConvertion?: () => void
}
interface HandleConvertionType extends CommonType {
    remoteFileName: string,
    format: string,
    onSuccess: (url: string) => void,
}
interface HandleUploadType extends CommonType {
    file: File,
    onSuccess: (fileName: string, convertOptions: FileFormatType[]) => void,
}

const handleUploadFile = async (file: File) => {
    const formdata = new FormData();
    formdata.append(`files`, file);

    return http.post('/uploadfile', formdata)
}

const initConvertionReq = async (filename: string, format: string) => {
    return http.post(`/convertfile`, {
        filename, format
    })
}

const getFileInfo = async (fileName: string) => {
    return http.get(`/fileinfo/${fileName}`)
}

export const handleFileUpload = async ({ file, onSuccess, onError, beforeConvertion }: HandleUploadType) => {
    // initializing before upload
    beforeConvertion && beforeConvertion()

    await handleUploadFile(file)
        .then(res => {
            console.log(res);
            const {fileName, convertOptions} = res.data;
            onSuccess(fileName, convertOptions)
            console.log(fileName);
        })
        .catch(({ response }) => { console.log("Error ==>", response); onError(response.data.message || 'Upload Error') })
}

export const handleFileConvertion = async ({ remoteFileName, format, onSuccess, onError, beforeConvertion }: HandleConvertionType) => {
    // initializing before convertion
    beforeConvertion && beforeConvertion()

    let fileName: string | null = remoteFileName;
    let startTime: number;

    await initConvertionReq(fileName, format)
        .then(res => {
            console.log(res);
            fileName = res.data.outputFileName;
            startTime = Number(Date.now() / 1000);
        })
        .catch(({ response }) => { console.log(response); fileName = null; onError(response.data.errorMsg||'Convertion Error') })

    if (!fileName) { return } // end the function

    // creating a funciton for checking is the file converted and available.
    const checkIsConverted = async () => {
        if (!fileName) { onError('No File Name'); return } // end the function
        let timePassed = Number(Date.now() / 1000 - startTime);
        if (timePassed > 300) {
            console.log('reach out of time')
            onError('Timeout, File Info Not Found');
            return
        }
        await getFileInfo(fileName)
            .then(res => {
                console.log("ERROR: ", res.data.isError);
                if (res.data.isError) {
                    setTimeout(checkIsConverted, 2000);
                    return
                }
                console.log(res);
                onSuccess(res.data.downloadUrl);
            })
            .catch(({ response }) => { console.log(response); onError(response.data.errorMsg||'File Info Not Found') })
    }
    // initialing a timer for checking for the file for every 2 sec
    setTimeout(checkIsConverted, 2000);

} 