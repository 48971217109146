import React, { FC, useState, useRef, CSSProperties } from 'react';
import styled from 'styled-components';
import { appTheme } from '../../globalStyle/appTheme';
import Button, { BtnStyle } from '../Button';
import { FileFormatType } from '../../typedefs';
import { Typography } from '@material-ui/core';
import { handleDrop, handleDragOver, handleDragEnter, handleDragLeave, browseFile } from './functions';
// const style = require('./DropBox.module.scss');

////////////////////////////////////////// Type defs  ///////////////////////////////////////////////////
type BorderType = CSSProperties['borderStyle'];
type StypeType = keyof typeof dropBoxStyles;
export interface WrapperProp {
    border: BorderType,
    dragIn?: boolean,
    color: keyof typeof colors,
}

export interface DropBoxProps {
    border?: BorderType,
    style?: StypeType,
    onFileReceive?: (files: File[]) => void,
    filterFiles?: FileFormatType[],
    onWrongFileReceive?: (filename: string) => void, // a callback which runs when file not match with filterFiles
}

export type StateType = {
    inDropZone: boolean,
    dropDepth: number, //0 | 1 | -1;
}

///////////////////////////////////////// global variables ///////////////////////////////////////////////////
const colors = {
    default: appTheme.colors.default,
    primary: appTheme.colors.primary,
    secondary: appTheme.colors.secondary,
}
const dropBoxStyles = {
    default: { buttonColor: 'default', wrapperColor: 'primary' },
    primary: { buttonColor: 'default', wrapperColor: 'secondary' },
    secondary: { buttonColor: 'secondary', wrapperColor: 'default' },
}

////////////////////////////////////// styled components ///////////////////////////////////////////////////
const Wrapper = styled.div<WrapperProp>`
    max-width : 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 300px;
    background-color: ${({ color }) => colors[color]};
    border-radius: 30px;
    border: ${({ border, dragIn }) => dragIn ? `5px ${border} grey` : 'none'};
    box-shadow: ${({ dragIn }) => dragIn ? "5px 5px 15px grey" : "none"};
    transition: 0.10s ease-in-out;
`

/////////////////////////////////////////// Functional components ////////////////////////////////////////
const DropBox: FC<DropBoxProps> = ({
    onFileReceive,
    border = 'dashed',
    style = 'default',
    filterFiles = [], // will run onFileReceive callback if the file is matched with given files in filefilter and on empty array it will run onFileReceive by default
    onWrongFileReceive
}) => {

    const fileInput = useRef<HTMLInputElement>(null);
    const [state, setState] = useState<StateType>({ inDropZone: false, dropDepth: 0 });
    const { inDropZone } = state;
    const buttonColor = dropBoxStyles[style].buttonColor as StypeType;
    const wrapperColor = dropBoxStyles[style].wrapperColor as BtnStyle['color'];
    const onDropCall = () => { setState({ ...state, inDropZone: false }) };
    const onDragOverCall = () => { setState({ ...state, inDropZone: true }) };
    // const onDragEnterCall = () => { setState({ ...state, dropDepth: state.dropDepth + 1 }) };
    const onDragLeaveCall = () => { setState({ ...state, inDropZone: false }) };

    return (
        <Wrapper {...{ border, dragIn: inDropZone, color: wrapperColor } as WrapperProp}
            onDrop={(e) => { handleDrop(e, filterFiles, onDropCall, onFileReceive, onWrongFileReceive) }}
            onDragOver={(e) => { handleDragOver(e, onDragOverCall) }}
            onDragEnter={(e) => { handleDragEnter(e) }}
            onDragLeave={(e) => { handleDragLeave(e, onDragLeaveCall) }}
        >
            { inDropZone ?
                <Typography color='textSecondary' variant='h3' align='center' >Ready to drop</Typography> :
                <>
                    <Typography color='textSecondary' variant='h3' align='center' >Just Drag & Drop file here</Typography>
                    <Typography variant='h5' color='textSecondary' >or</Typography>

                    <Button size='large' color={buttonColor} onClick={() => { fileInput.current?.click() }} >
                        Browse File
                            <input ref={fileInput} type="file" name='file'
                            onChange={(e) => { browseFile(e, filterFiles, onFileReceive, onWrongFileReceive) }}
                            multiple style={{ display: "none" }} accept=''
                        />
                    </Button>
                </>
            }
        </Wrapper>
    );
};


//// Exports
export default React.memo(DropBox);