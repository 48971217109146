import { appTheme } from '../../globalStyle/appTheme';
import { displayFlex } from '../../utils/css-generators';
import { StepConnector } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242, 113, 33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242, 113, 33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

export const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            `linear-gradient( 136deg, ${appTheme.colors.secondary} 0%, ${appTheme.colors.fourth} 60%, rgb(133, 130, 130) 100%)`,
        boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.438)',
    },
    completed: {
        backgroundImage:
            `linear-gradient( 136deg, ${appTheme.colors.secondary} 0%, ${appTheme.colors.fourth} 60%, rgb(133, 130, 130) 100%)`,
    },
});

// import styled from 'styled-components';


// export const StepperWrap = styled.div`
//     background-color: orange;
//     ${displayFlex({ align: 'normal' })}
//     width: 100%;

//     >div{
//         ${displayFlex({ })}
//         width: 100%;
//         background-color: yellow;
//         >span{
//             width:100%;
//             height: 2px;
//             background-color: black;
//         }
//     }

// `

// export const StepWrap = styled.div`

// `

// export const IconWrap = styled.div`
//     ${displayFlex({})}
//     color:white;
//     height: 40px;
//     width: 40px;
//     border-radius: 50%;
//     background: linear-gradient( 136deg, ${appTheme.colors.secondary} 0%, ${appTheme.colors.fourth} 60%, rgb(133, 130, 130) 100%);
// `