import React, { FC, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import Button from '../Button';
import { displayFlex } from '../../utils/css-generators';
import { FileStateType, } from './'
import { FormControl, Select, InputLabel, Typography, IconButton } from '@material-ui/core';
import { Close } from "mdi-material-ui";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getFileExtension } from '../../utils/generalFunctions';
import { handleFileConvertion, handleFileUpload } from '../../services/handleFileConvertion';
import { appTheme } from '../../globalStyle/appTheme';
import { FileFormatType } from '../../typedefs';

////////////////////////////////////////// Type defs  ///////////////////////////////////////////////////
export interface RowProps extends FileStateType {
    onRowRemove?: (id: number) => void,
    defaultConvertFrmt?: string,
}
export interface StateType extends FileStateType {
    frmtToCnvrt: string | null,
}
type WrapperProp = { state: "loading" | "error" | 'success' }

///////////////////////////////////////// global variables ///////////////////////////////////////////////////
const useStyles = makeStyles(() =>
    createStyles({
        formControl: { minWidth: 95, },
    }),
);

////////////////////////////////////// styled components ///////////////////////////////////////////////////
const rowColor = appTheme.colors.default
const loadColor = 'rgba(255, 197, 109, 0.5)'
const colorMoving = keyframes`
    ${Array(51).fill(0, 0, 100).map((_, i) => {
    const frame = i * 2;
    i = (i * (160 / 100)) * 2
    return `${frame}% { background: linear-gradient(90deg,  ${rowColor} ${-60 + i}%, ${loadColor} ${-30 + i}%, ${rowColor} ${0 + i}%); }`
}).join('\n')}
`
const RowWrapper = styled.div<WrapperProp>`
    width : 100%;
    background-color: ${rowColor};
    animation: ${({ state }) => state === 'loading' ? colorMoving : ''} 1s ease-in-out infinite ;
    padding: 10px;
    margin-bottom: 2px;
    border-bottom: 5px solid ${({ state }) => state === 'loading' ? "orange" : state === 'error' ? 'red' : 'green'};
    transition: 0.20s ;
    ${displayFlex({ align: 'center', justify: 'center', wrap: 'wrap' })}
    & > span{
        ${displayFlex({ align: 'center', justify: 'normal' })}
        flex: 20%;
    }
    &>div{
        ${displayFlex({ align: 'center', justify: 'flex-end' })}
        flex: 10%;
    }
`
const FileName = styled.span`
    font-family: Arial, Helvetica, sans-serif;
    @media (max-width: 600px){
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; 
        max-width: 220px;
    }
`
const FileImgName = styled.span`
    flex: 30% !important;
    @media (max-width: 600px){
        flex: 70% !important;
    }
`
const StyleDiv = styled.div<{ query: string, innerCss: string }>`
    @media ( ${({ query }) => query} ) { 
        ${({ innerCss }) => innerCss}
    }
`

/////////////////////////////////////////// Functional components ////////////////////////////////////////
const Row: FC<RowProps> = ({ defaultConvertFrmt, errorMsg, isError, id, remoteFileName, fetchingAction, isFetching, file, fileUrl, onRowRemove }) => {
    const classes = useStyles();
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false)
    const [state, setState] = React.useState<StateType>({ errorMsg, isError, id, remoteFileName, fetchingAction, isFetching, file, fileUrl, frmtToCnvrt: defaultConvertFrmt || null });
    const [formats, setFormats] = useState<string[]>([]); //'txt', 'pdf', 'svg', 'docx'
    const wrapperState: WrapperProp['state'] = state.isFetching ? "loading" : state.isError ? "error" : 'success'
    let wrapperRef: HTMLDivElement | null = null;

    const RemoveRowBtn = () => (
        <IconButton size='small' onClick={() => { onRowRemove && onRowRemove(id) }} >
            <Close />
        </IconButton>
    )
    const handleFileTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState({ ...state, [name]: event.target.value, });
    };
    const onSuccessConvertion = (url: string) => {
        setState({ ...state, fileUrl: url, isFetching: false, fetchingAction: null })
    }
    const onError = (errorMsg: string) => {
        setState({ ...state, errorMsg: errorMsg, isError: true, isFetching: false, fetchingAction: null })
    }
    const onSuccessUpload = (fileName: string, convertOptions: FileFormatType[]) => {
        setState({ ...state, remoteFileName: fileName, isFetching: false, fetchingAction: null });
        setFormats(convertOptions.map(item => item.extension));
        setIsFileUploaded(true);
    }
    const handleUpload = () => {
        handleFileUpload({
            file: state.file,
            onSuccess: onSuccessUpload,
            onError: onError,
            beforeConvertion: () => { setState({ ...state, fetchingAction: "Uploading...", isFetching: true }) }
        })
    }
    const handleConvertion = async () => {
        if (state.isFetching || !state.remoteFileName || !state.frmtToCnvrt) {
            return
        }
        handleFileConvertion({
            format: state.frmtToCnvrt,
            remoteFileName: state.remoteFileName,
            onError: onError,
            onSuccess: onSuccessConvertion,
            beforeConvertion: () => { setState({ ...state, fetchingAction: "Converting...", isFetching: true }) },
        })
    }

    // Initialize after component render
    useEffect(handleUpload, []);
    useEffect(() => { if (isFileUploaded) { handleConvertion() } }, [isFileUploaded]);

    /* this useEffect callback adjust the position of the wrapper elem by scrolling in 
    the center of the screen  */
    useEffect(() => {
        // const scrollHeight = document.body.scrollHeight; // getting scroll height of the window
        const elemTopPos = wrapperRef?.offsetTop as number; // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetTop
        const windowHeight = window.innerHeight;
        const elemHeight = wrapperRef?.offsetHeight as number; // getting elem's height
        const elemCenterPos = elemTopPos - Number(windowHeight / 2 - elemHeight);
        window.scrollBy({ top: elemCenterPos, behavior: 'smooth' })
    }, [])

    /// component render ////////////////////////////////////
    return (
        <RowWrapper state={wrapperState} ref={node => wrapperRef = node} >

            <FileImgName >
                <img width='50' src={require('../../assets/fileImg.png')} alt="file-Img" />
                <FileName >{file.name}</FileName>
            </FileImgName>
            <StyleDiv query='min-width:601px' innerCss='display:none !important;\nflex:30% !important;'  >
                <RemoveRowBtn />
            </StyleDiv>

            <span style={{ margin: 'auto 5px', display: state.isFetching ? undefined : 'none' }} >
                <Typography>{state.fetchingAction}</Typography>
            </span>

            {/* this message will display after convertion or error */}
            <span style={{ margin: 'auto 5px', color: state.isError ? "red" : 'green', display: (state.fileUrl || state.isError) ? undefined : 'none' }} >
                <Typography color='inherit' >
                    {state.isError ? state.errorMsg : state.fileUrl ? "Converted" : null}
                </Typography>
            </span>

            {/* this message will display on Error */}
            {/* <span style={{ margin: 'auto 5px', flex: '40%', color: 'red', display: state.isError ? undefined : 'none' }} >
                <Typography color='inherit' >{state.errorMsg}</Typography>
            </span> */}

            <span style={{ margin: 'auto 5px', display: (state.isFetching || state.fileUrl || state.isError) ? 'none' : undefined }} >
                <Typography color='textSecondary' variant='subtitle1' >{getFileExtension(file.name.toUpperCase())}</Typography>
                <Typography variant='subtitle1' style={{ margin: '0 20px' }} >to</Typography>
                <FormControl size='small' variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-file-type-native-simple">Type</InputLabel>
                    <Select
                        native
                        value={state.frmtToCnvrt || ''}
                        onChange={handleFileTypeChange}
                        label="FileType"
                        inputProps={{
                            name: 'frmtToCnvrt',
                            id: 'outlined-file-type-native-simple',
                        }}
                    >
                        <option aria-label="None" value={undefined} />
                        {formats.map((frmt, idx) => {
                            if (frmt.toLowerCase() === getFileExtension(state.file.name.toLowerCase())) return
                            return <option value={frmt} key={idx} >{frmt.toUpperCase()}</option>
                        })}
                    </Select>
                </FormControl>
            </span>

            <div style={{ margin: 'auto 5px', display: (state.isFetching || state.isError) ? 'none' : undefined }} >
                {state.fileUrl ?
                    <a href={state.fileUrl} ><Button variant='outline' color='secondary' >Download</Button></a> :
                    <Button disabled={!Boolean(state.frmtToCnvrt)} variant='outline' color='secondary' onClick={handleConvertion} >
                        Convert
                    </Button>
                }
            </div>

            {/* <RemoveRowBtn onClick={() => { onRowRemove && onRowRemove(id) }} */}
            {/* display={window.innerWidth < 600 ? 'none' : undefined} /> */}
            {/* <div >
                <IconButton size='small' onClick={() => { onRowRemove && onRowRemove(id) }} >
                    <Close />
                </IconButton>
            </div> */}
            <StyleDiv query='max-width:600px' innerCss='display:none !important;' ><RemoveRowBtn /></StyleDiv>
        </RowWrapper>
    )
}

export default React.memo(Row);
